import { ReactElement } from 'react'
import styled from 'styled-components'
import { sizes } from '../../styles/sizes'
import { fontStyles } from '../../styles/variables'
import GrandientLine from '../sections/components/grandient-line'
import Container from './components/container'
import React from 'react'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: 80px;
  gap: 376px;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 212px;
  }
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    gap: 4rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 568px;
  width: 472px;
  background: white;
  box-shadow: 6px 6px 24px #00000029;
  z-index: 1;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    width: 396px;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 520px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 274px;
  }
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  height: 100%;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    padding: 0 1rem;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex: 1;
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  width: 100%;
  height: 4rem;
  ${fontStyles.contactbutton}
  cursor: pointer;
  background-color: white;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    height: 56px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    margin: 1rem 0;
  }
`
const Input = styled.input`
  box-shadow: 2px 2px 6px #00000029;
  border: 0px;
  ${fontStyles.contactinput}
  height:3rem;
  width: 100%;
  padding-left: 1.5rem;
  &::placeholder {
    color: #ececec;
  }
`
const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${fontStyles.labelinput}
  width:100%;
`

const TextArea = styled.textarea`
  box-shadow: 2px 2px 6px #00000029;
  border: 0px;
  ${fontStyles.contactinput}
  height:6rem;
  padding-left: 1.5rem;
  &::placeholder {
    color: #ececec;
  }
  max-width: 100%;
  resize: none;
`

export const SectionWithForm = ({
  heading,
  text,
  formName,
  formMessage,
  formEmail,
  buttonText,
  namePlaceholder,
  messagePlaceholder,
  emailPlaceholder,
}: {
  heading: string
  text: ReactElement
  formName: string
  formMessage: string
  formEmail: string
  buttonText: string
  namePlaceholder: string
  messagePlaceholder: string
  emailPlaceholder: string
}) => {
  return (
    <Wrapper>
      <Container heading={heading} text={text} />
      <FormContainer>
        <GrandientLine />
        <Form action="mailto:info@labrulez.cz" method="get">
          <Row>
            <Label>
              <span>{formName}</span>
              <Input type="text" name="subject" required placeholder={namePlaceholder} />
            </Label>
          </Row>
          <Row>
            <Label>
              <span>{formEmail}</span>
              <Input type="email" name="email" required placeholder={emailPlaceholder} />
            </Label>
          </Row>
          <Row>
            <Label>
              <span>{formMessage}</span>
              <TextArea rows={5} name="body" placeholder={messagePlaceholder} />
            </Label>
          </Row>
          <Row>
            <Button type="submit">{buttonText}</Button>
          </Row>
        </Form>
      </FormContainer>
    </Wrapper>
  )
}
