import styled from 'styled-components'
import { BigHeading } from './components/big-heading'
import React from 'react'
import Img, { FixedObject } from 'gatsby-image'
import Phone from '../../images/phone.svg'
import Mail from '../../images/mail.svg'
import { fontStyles } from '../../styles/variables'
import { sizes } from '../../styles/sizes'
const Container = styled.div`
  display: flex;
  gap: 240px;
  justify-content: center;
  padding-bottom: 11.5rem;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 500px;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 251px;
  }
`
const OurTeam = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 100%;
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`
const RowRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 100%;
  }
`
const Contact = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  word-wrap: break-word;
  word-break: break-all;
  ${fontStyles.contact}
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    gap: 0.5rem;
    text-align: start;
    width: 100%;
  }
`
const Decsription = styled.span`
  ${fontStyles.teammemberabout}
`
const Name = styled.h1`
  ${fontStyles.teammembername}
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 530px;
  height: 650px;
  box-shadow: #00000029 6px 6px 24px;
  padding-bottom: 2.5rem;
  margin-top: 5rem;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 274px;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
`
const LabrulezInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 2.5rem;
`
const LabrulezInfo = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-line;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
    gap: 1rem;
  }
`
const Divider = styled.div`
  height: 52px;
  width: 2px;
  background-color: #ececec;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    display: none;
  }
`
const DividerHorizontal = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ececec;
`
const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
  }
`
const Map = styled.iframe`
  width: 530px;
  height: 530px;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 274px;
    height: 274px;
  }
`
const StyledImage = styled(Img)`
  border-radius: 50%;
`
export const SectionOurTeam = ({
  heading,
  members,
  companyName,
  companyInfo,
  emailLabrulez,
  address,
}: {
  heading: string
  members: {
    name: string
    image: FixedObject
    description: string
    phone?: string
    email?: string
  }[]
  companyName: string
  companyInfo: string
  emailLabrulez: string
  address: string
}) => {
  return (
    <Container>
      <Left>
        <BigHeading big={false} gradient={true} text={heading} />
        <OurTeam>
          {members.map((member, index) => (
            <>
              <Row>
                <StyledImage fixed={member.image} />
                <RowRight>
                  <Name>{member.name}</Name>
                  <Decsription>{member.description}</Decsription>
                  {member.phone && (
                    <Contact>
                      <Phone />
                      {member.phone}
                    </Contact>
                  )}
                  {member.email && (
                    <Contact>
                      <Mail />
                      {member.email}
                    </Contact>
                  )}
                </RowRight>
              </Row>
              {index < members.length - 1 && <DividerHorizontal />}
            </>
          ))}
        </OurTeam>
      </Left>
      <Right>
        <Map
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.59147508035!2d14.446200376875643!3d50.09393511296919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94a4b2392cab%3A0x7de1d004c08dd5d1!2zUG9ixZllxb5uw60gMjQ5LzQ2LCAxODYgMDAgS2FybMOtbg!5e0!3m2!1scs!2scz!4v1693486894567!5m2!1scs!2scz"
          width="530"
          height="375"
        ></Map>
        <Wrapper>
          <LabrulezInfoContainer>
            <Name>{companyName}</Name>
            <LabrulezInfo>
              <Contact>{address}</Contact>
              <Divider />
              <Contact>{companyInfo}</Contact>
            </LabrulezInfo>
            <DividerHorizontal />
            <ContactWrapper>
              <Contact>
                <Phone />
                {members[0].phone}
              </Contact>
              <Contact>
                <Mail />
                {emailLabrulez}
              </Contact>
            </ContactWrapper>
          </LabrulezInfoContainer>
        </Wrapper>
      </Right>
    </Container>
  )
}
